// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://74.208.206.18:4001/',
  // url: 'http://198.251.65.146:4001/',
  // url: 'https://stageapi.epicbusinessgroup.us/',  
     url : 'https://endpoint.epicbusinessgroup.us/',
  // frontUrl: 'http://198.251.65.146:4004/',
  frontUrl: 'https://epicbusinessgroup.us/',
  chatUrl:'https://chat.epicbusinessgroup.us',
  // url: 'http://66.179.251.9:6004/', 

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
