import { Injectable } from '@angular/core';
 
const credentialsKey = 'credentials'; 
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, } from 'rxjs/operators'; 
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
// import { CredentialsService } from '../auth/credentials.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  private _credentials: any | null = null;
  private _baseUrl = environment.url;
  constructor(private httpClient: HttpClient) {
    // const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    const savedCredentials =  localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Sets the user credentials. 
   */
  setCredentials(credentials?, remember?: boolean) {
    this._credentials = credentials || null;
    if (credentials) {
      // const storage = remember ? localStorage : sessionStorage;
      const storage =  localStorage ;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      storage.setItem('token', credentials.access_token);
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      localStorage.removeItem('token');
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    // const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    const savedCredentials = localStorage.getItem(credentialsKey); 
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    } 
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials() {
    return this._credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get token() {
    return this._credentials ? this._credentials.access_token : '';
  }

  /**
     * @method
     * @name logout
     * Logs out the user and clear credentials.
     * @return boolean
     */
  logout() { 
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  isLogin() {
    let token = localStorage.getItem('token');
    if (token) return true;
    else return false;
  }
  autoLoginFunc(context) {
    return this.httpClient.post(this._baseUrl + 'user/auto/login'+context,'').pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  
  addCookie(key, value) {
    localStorage.setItem(key, value);
  }

  getCookie(key) {
    let item = localStorage.getItem(key);
    return item;
  }
  addCookieObject(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  getCookieObject(key) {
    return JSON.parse(localStorage.getItem(key));
  }


  handleError(error: HttpErrorResponse) {
 
    if (error.error.code == 401) {
      return throwError('');
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
  
}
