import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

    constructor(private spinner: NgxSpinnerService,public router: Router, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.error.code === 401) {
                this.toastr.error(err.error.message,'Error');
                var myItem:any
                if(localStorage.getItem('remember')){
                    myItem = JSON.parse(localStorage.getItem('remember'));
                
                }
               
localStorage.clear();
localStorage.setItem('remember',myItem);
                window.localStorage.clear();
                this.router.navigateByUrl('/');
                this.spinner.hide()
            }else  if (err.status == 400) {
                if (err.error.message && err.error.message!==''){
                  // this.toastr.error(err.error.message,'Error');
                }
                else if (err.error.error.message && err.error.error.message !== "") {
                    // this.toastr.error(err.error.error.message,'Error');
                  }  
                }else  if (err.status == 404) { 
                  //('error 33',err.error)
                  if (err.error.message && err.error.message!==''){
                    this.toastr.error(err.error.message,'Error');
                  }
                   else  if (err.error.error.message && err.error.error.message !== "") {
                    this.toastr.error(err.error.error.message,'Error');
                  } 
                }else if (err.status === 500) {
                  if (err.error.message!==''){
                      this.toastr.error(err.error.message,'Error');
                    }
                    else if (err.error.error.message !== "") {
                        this.toastr.error(err.error.error.message,'Error');
                      } 
              }
            // const error = err.error.message || err.error.code;
            return throwError(err.error);
        }))
    }
}
