import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
// import { CredentialsService } from '../auth/credentials.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestServiceService {
  actionType = new BehaviorSubject('active')
  private _baseUrl = environment.url;
addedById = new BehaviorSubject('');
prospectiveId = new BehaviorSubject('');
  constructor(
    // private credentialsService: CredentialsService,
    private httpClient: HttpClient) {

  }


  add(context) {
    return this.httpClient.post(this._baseUrl + `multi/request`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  addClient(context) {
    return this.httpClient.post(this._baseUrl + `client`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  sendMessage(context) {
    return this.httpClient.post(this._baseUrl + `message`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  viewProspective(context) {
    return this.httpClient.post(this._baseUrl + `view/prospecte`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  FavoriteProspective(context) {
    return this.httpClient.post(this._baseUrl + `favourite`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  update(context) {
    // context.id = id
    return this.httpClient.put(this._baseUrl + `request`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  unreadMessage(context) {
    // context.id = id
    return this.httpClient.put(this._baseUrl + `unread/message`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getCat(id) {
    return this.httpClient.get(this._baseUrl + 'getSingleCategory?id=' + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getMakesList() {
    let params = new HttpParams().set('?search','').set('isDeleted','false')
    return this.httpClient.get(this._baseUrl + 'makes'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getCountry() {
    return this.httpClient.get(this._baseUrl + 'countrys').pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getTypes(data) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'getAllcategoryType?'+data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  } 

  getAllRequest(data) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'client/prospecte'+data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  myRequest(data) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'frontend/requests'+data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getDetails(params) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'request'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getClients(){
    return this.httpClient.get(this._baseUrl + 'clients').pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getMessages(params) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'messages'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getprospecteDetails(params) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'prospecte'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getAllMajorCategories(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'getAllMainCategories', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getAllSubCategoriesOfMainCat(id) {
    return this.httpClient.get(this._baseUrl + 'getAllSubCategory?categoryId=' + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  deleteRecord(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.delete(this._baseUrl + 'delete', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

   

  removeImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.delete(this._baseUrl + 'deleteImage', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  status( data) {
    let url = this._baseUrl + 'change/status'

    return this.httpClient.put(url,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

   

  uploadImage(fileToUpload: File,type) {
    let params = '?modelName='+type
    const formData: FormData = new FormData();
    formData.append('data', fileToUpload, fileToUpload.name);
    formData.append('modelName',type);
    return this.httpClient.post(this._baseUrl + `uploadImages`+params,formData).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.delete(this._baseUrl + 'deleteImage', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getParams(parameters) {
    let params = new HttpParams();
    Object.keys(parameters).map((key) => {
      params = params.set(key, parameters[key]);
    })
    return params;
  }

  handleError(error: HttpErrorResponse) {
    //(error);
    if (error.error.code == 401) {
      return throwError('');
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }



}