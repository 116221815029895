import { HttpClient, HttpClientModule, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CredentialsService } from './credentials.service';
export interface SignupContext {
  program: string;
  companyName: string;
  companyUrl: string;
  salutaion: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
}

export interface LoginContext {
  email: string;
  password: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _baseUrl = environment.url;

  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService
  ) { }

  /**
* @method
* @name login
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'password': string
* }
* @param context The login parameters.
* @return Promise.
*/
login(context) {
  return this.httpClient.post(this._baseUrl + `user/signin`, context).pipe(
    map((response: any) => {
      this.credentialsService.setCredentials(response.data);
      return response;
    }),
    catchError(this.handleError)
  );
}

Signup(url,context) {
  // let param = this.getParams(context);
  return this.httpClient.post(this._baseUrl + url, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )

}

autoLogin(context) {
  // let param = this.getParams(context);
  return this.httpClient.post(this._baseUrl + `user/auto/login`, context).pipe(
    map((response: any) => {
      this.credentialsService.setCredentials(response.data);

      return response;

    }),
    catchError(this.handleError)
  )

}

// logout() {
//   this.credentialsService.setCredentials();
//   return of(true);
// }

changePass(params) {
  let param = this.getParams(params);
  return this.httpClient.post(this._baseUrl + 'change/password', param).pipe(
    map((response: any) => {      
      return response;
    }),
    catchError(this.handleError)
  )
}



sendEmail(formData): Observable<any> {
  return this.httpClient.post(this._baseUrl + `forgot/password`, formData).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

resetPassword(formData): Observable<any> {
  return this.httpClient.put(this._baseUrl + `reset/password`, formData).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}

handleError(error: HttpErrorResponse) {
  // //(error);
  if (error.error.code == 401) {
    return throwError('');
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  } else if (error.error.code == 400) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}
