import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';

// import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

// import {do} from  'rxjs/add/operator/do';

const TOKEN_KEY = 'token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    protected debug = false;
    private APIToken = null;
    token: any;
    currentUserData :any;

    constructor(private authService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    this.currentUserData = JSON.parse(localStorage.getItem('credentials'));
    if(localStorage.getItem("token") && localStorage.getItem("tempUser")){
      this.token = JSON.parse(localStorage.getItem("token"));
    }else{
      this.token =  localStorage.getItem("token");
    }
    

    // //('sdsdsd',this.currentUserData);
    if(this.token){
        let headers = {};
        if (this.token) {
          headers = {
            'Authorization': `Bearer ${this.token}`,
            'Access-Control-Allow-Origin': '*', 
            'Accept': 'application/json', 
          };
       
        request = request.clone({
          setHeaders: headers
        });
    } 
    }
   
    return next.handle(request);
  }
}