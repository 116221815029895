import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { CredentialsService } from '../auth/credentials.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _baseUrl = environment.url;

  constructor(
    private credentialsService: CredentialsService,
    private httpClient: HttpClient) {

  }

  sendTop() {
    window.scrollTo(500, 0);
  }

  update(context, id) {
    return this.httpClient.put(this._baseUrl + `editProfile?id=` + id, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getUserDetail(id) {
    return this.httpClient.get(this._baseUrl + 'user?id=' + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  uploadImage(object) {
    return this.httpClient.post(this._baseUrl + `uploadImages`, object).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  inviteFriend(body) {
    return this.httpClient.post(this._baseUrl + `sendInvite`, body).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getCourses(id, param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'userCourse?plan_id=' + id, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getAllCourses() {
   
    return this.httpClient.get(this._baseUrl + 'courses').pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getSingleCourse(id) {
    return this.httpClient.get(this._baseUrl + 'course?id=' + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  downloadVideo(file) {
    return this.httpClient.get(this._baseUrl + 'downloadVideo?fileName=' + file, { responseType: 'blob' }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getAllInvitedMembers(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'getInvitedUsersList', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  resendInvitation(ID) {
    return this.httpClient.post(this._baseUrl + `reSendInvite?id=` + ID, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateInvitedUser(context, id) {
    return this.httpClient.put(this._baseUrl + `UpdateInviteUser?id=` + id, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getParams(parameters) {
    let params = new HttpParams();
    Object.keys(parameters).map((key) => {
      params = params.set(key, parameters[key]);
    })
    return params;
  }

  handleError(error) {
    //(error);
    if (error.code == 404 || error.error.code == 404) {
      return throwError(error.message || error.error.message);
    } else if (error.error.code == 301) {
      return throwError(error.error.message);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  getTransitionDetails(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'transactionList?', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }




}
